import React, { useState } from "react";
import logo from "./assets/tenderguide.svg";
import cateringTerms from "./assets/terms_catering.json";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import ReactWordcloud, { Optional, Options, Word } from "react-wordcloud";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { Disable } from "react-disable";
import ExpandModal from "./ExpandModal";

const productGroups = [
  "Catering",
  "Schoonmaak",
  "ICT",
  "Gas",
  "Elektriciteit",
  "Bedrijfskleding",
  "Zonnepanelen",
  "Kantoormeubilair",
  "Dienstreizen",
  "Dienstauto’s",
  "Wegen",
  "Openbare verlichting",
  "Kantoorgebouwen (nieuwbouw + renovatie)",
  "Contractvervoer en transportdiensten",
];

const callbacks = {
  getWordColor: (word: Word) => {
    return `rgba(0, 0, 255, ${(word.value * 2) / 100})`;
  },
  getWordTooltip: (word: Word) => `${word.text} (${word.value})`,
};

const options: Optional<Options> = {
  rotations: 2,
  rotationAngles: [-90, 0],
  scale: "log",
  fontFamily: "Maven Pro",
  deterministic: true,
};

function App() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  console.log([...cateringTerms].splice(0, 10));

  return (
    <div style={{ padding: 20 }}>
      <header style={{ marginBottom: 40 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Onderzoek productgroepen</strong>
          </div>
          <img height="50px" src={logo} alt="logo" />
        </div>

        <Divider></Divider>
      </header>
      <main>
        <Grid container spacing={4}>
          {productGroups.map((title) => {
            const active = title === "Catering";

            return (
              <Grid item md={3}>
                <Disable disabled={!active} disabledOpacity={0.4}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "4px 0",
                    }}
                  >
                    <Typography variant="overline" display="block" gutterBottom>
                      {title}
                    </Typography>
                    <IconButton onClick={() => setModalOpen(true)} size="small">
                      <FullscreenIcon></FullscreenIcon>
                    </IconButton>
                  </div>

                  <Paper variant="outlined" style={{ minHeight: 400 }}>
                    {active ? (
                      <ReactWordcloud
                        words={cateringTerms.map((t) => ({
                          text: t[0] as string,
                          value: Math.round((t[2] as number) * 10000),
                        }))}
                        callbacks={callbacks}
                        options={options}
                      />
                    ) : null}
                  </Paper>
                </Disable>
              </Grid>
            );
          })}
        </Grid>
      </main>

      <ExpandModal
        terms={cateringTerms}
        open={modalOpen}
        title={"Catering"}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
}

export default App;

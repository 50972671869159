import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  open: boolean;
  onClose(): void;
  terms: any[];
  title: string;
}

const ExpandModal: React.FC<Props> = ({ open, onClose, title, terms }) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth={"lg"}>
      <div
        style={{
          padding: "10px 26px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={"h5"}>{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Woord</TableCell>
              <TableCell align="right">Score</TableCell>
              <TableCell align="right">Aantal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.map((term) => (
              <TableRow key={term[0]}>
                <TableCell>{term[0]}</TableCell>
                <TableCell align="right">{term[2].toFixed(3)}</TableCell>
                <TableCell align="right">{term[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandModal;
